<template>
    <div class="f-list-box">
        <!-- 面包屑 -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>自建表单</el-breadcrumb-item>
            <el-breadcrumb-item>列表</el-breadcrumb-item>
        </el-breadcrumb>
         <!-- 顶部栏 -->
        <div class="header-bar">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item>
                     <el-input size="normal" @input="search" placeholder="请输入关键字" v-model="form.keys">
                        <!-- <template slot="prepend">关键词</template> -->
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="margin" @click="search" icon="el-icon-search" size="small">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="content">
            <el-table
            :data="list"
            v-loading="loading"
            element-loading-text="请稍等..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            style="width: 100%">
            <el-table-column
                prop="date"
                label="日期"
                width="180">
            </el-table-column>
            <el-table-column
                prop="name"
                label="名称"
                width="180">
            </el-table-column>
            <el-table-column
                prop="remark"
                label="备注">
                <template slot-scope="scope">
                        {{scope.remark||'无'}}
                    </template>
            </el-table-column>
            <el-table-column
                label="操作">
                <template slot-scope="scope">
                        <div class="flex">
                            <el-popover
                            placement="top-start"
                            title="选择"
                            width="200"
                            trigger="hover">
                            <div class="list-con">
                                <el-button type="success" size="small" @click="addTo(scope.row)" round>服务项目</el-button>
                               </div>
                            <el-button slot="reference" size="small" type="success"  round>
                                添加到
                            </el-button>
                        </el-popover>
                        <div class="box-btn">
                             <el-button type="warning" size="small" round @click="edit(scope.row)">修改</el-button>
                             <el-button type="danger" size="small" round @click="deleteI(scope.row.id)">删除</el-button>
                             <el-button type="primary" size="small" @click="assist(scope.row)" round plain>辅助统计</el-button>
                        </div>
                        </div>
                </template>
            </el-table-column>
            </el-table>
            <!-- page分页 -->
            <div class="pageNav" v-if="list.length>=1">
                <el-pagination
                    hide-on-single-page
                    v-model="page"
                    layout="prev, pager, next"
                    @current-change="currentChange"
                    :page-count="totalPage">
                </el-pagination>
                <el-dropdown>
                    <span class="el-dropdown-link">
                        每页显示 {{pageSize}} 条<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="selectPageSize(item)" v-for="item in pageArr" :key="item">
                            {{item}}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            form:{
                keys:''
            },
            loading:false,
            page:1,
            list:[],
            pageSize:6,
            pageArr:[6,10,15,20],
            totalPage:1
        }
    },
    created(){
        this.getForms()
    },
    methods:{
        //添加辅助
        assist(row){
            console.log(row.id)
            //console.log(url)
            sessionStorage.setItem("testList",JSON.stringify(row))
            this.$router.push({path:'/Form/addTest',params:{id:row.id,title:row.name}})
            //window.open(url.href, '_blank');
        },
        //添加到
        addTo(data){
            let params = {
                method:"addProject",
                name:data.name,
                remark:data.remark,
                content:data.content,
                status:0
            }
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                this.loading = false 
            }).catch(err=>{
                //err
                console.log(err.data)
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        currentChange(e){
            console.log(e)
            this.page=e 
            this.getForms()
        },
        //选择每页显示
        selectPageSize(e){
            this.pageSize = e
            this.search(this.page)
        },
        //删除
        deleteI(id){
            this.$confirm(this.CONST.DEL_CONFIRM)
                .then(res=> {
                    if(res){
                        let params = {
                            method:"deleteForms",
                            id
                        }
                        this.$ajax.post(this.API.api,params).then(res=>{
                            console.log(res)
                            this.$message({
                                message: res.msg||'删除成功！',
                                type: 'success'
                            });
                            this.search()
                        }).catch(err=>{
                            //Err
                            this.$message({
                                message: err.msg?err.msg:err,
                                type: 'warning'
                            });
                        })
                    }
                })
        },
        //修改
        edit(data){
            localStorage.setItem('currentForm',JSON.stringify(data))
            let url = this.$router.resolve({path:'/Form/add/',query:{update:true}})
            //console.log(url)
            window.open(url.href, '_blank');
        },
        search(page){
            this.page = page||1;
            this.list = []
            this.getForms()
        },
        getForms(){
            let params = {
                method:"getForms",
                page:this.page,
                pageSize:this.pageSize,
                keys:this.form.keys
            }
            this.loading = true
            this.$ajax.post(this.API.api,params).then(res=>{
                if(res.data.totalPage){
                    this.totalPage = res.data.totalPage
                    this.list = res.data.list
                }
                this.loading=false
                //this.list = res.data
            }).catch(err=>{
                //Err
                console.log(err)
                this.loading=false
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        }
    }
}
</script>
<style lang="less">
// 分页数
    .pageNav{
        padding: 10px 0;
        display: flex;
        .el-dropdown{
            line-height: 28px;
        }
    }
    .flex{
        display: flex;
        .box-btn{
            margin-left: 10px;
        }
    }
</style>
